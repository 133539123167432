@use "../assets/theme" as theme;

.landing {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: theme.$nigga;
	color: theme.$nigger;
	display: flex;
	flex-direction: column;

	.header {
		width: 98vw;
		height: 10vh;
		margin: 1vh 1vw 1vh 1vw;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.branding {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 5vh;
			font-weight: 700;

			img {
				height: 10vh;
				padding-right: 0.8vw;
			}
		}

		.user {
			display: flex;
			flex-direction: row;
			font-size: 1.5vw;
			font-weight: 700;

			a {
				text-decoration: none;
				color: inherit;
			}
		}
	}

	.main {
		flex-grow: 1;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-bottom: 5rem;
		margin-left: 1.5rem;

		.content {
			display: flex;
			flex-direction: column;
			padding: 3rem;

			img {
				width: auto;
				height: 50vh;
			}

			.motto {
				font-size: 3rem;
				margin-bottom: 0;
				margin-top: 0;
			}

			.description {
				font-size: 1.25rem;
			}
		}

		.getstarted {
			color: theme.$nigga;
			background: theme.$nigger;
			font-size: 1.25rem;
			padding: 0.5rem 1.25rem;
			border: 1px solid #b6b6b6;
			border-radius: 0.5rem;
			letter-spacing: 1px;
			box-shadow: 0px 3px 3px theme.$highlight;
			text-align: center;
			max-width: 125px;
			text-decoration: none;
		}
	}
}
