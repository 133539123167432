@use "assets/theme" as theme;

body {
	margin: 0;
	font-family: Judson, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: theme.$nigga;
}

code {
	font-family: Judson, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "Judson";
	src: local("Judson"), url(./assets/Judson.ttf) format("truetype");
}
